import React from "react";
import "../../App.css";
import VoucherLedger from "../VoucherLedger";

function VoucherLedgerPage({ sessionObject }) {
    // if (!sessionObject.sessionVariables["modules"].includes("vinod-report")) {
    //     return;
    // }
    return (
        <div className="page-vehicle">
            <VoucherLedger sessionObject={sessionObject} />
        </div>
    );
}

export default VoucherLedgerPage;
