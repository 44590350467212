import React from "react";
import { SERVER_URL } from "../config/config.js";
import "./challanReport.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ReportTable from "./ReportTable.js";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import ReportExcel from "./ReportExcel.js";
import { useHistory } from "react-router-dom";

let excelData = [];

function ExtraPaymentReport({ sessionObject }) {
    const history = useHistory();
    const columns = React.useMemo(
        () => [
            {
                Header: "Payment No",
                accessor: "id",
                width: "100px",
                minWidth: "10px",
                canFilter: true,
            },
            {
                Header: "Created Date",
                accessor: "created_date",
                width: "100px",
                minWidth: "10px",
                Cell: ({ value }) => {
                    let date = new Date(value);
                    let day = String(date.getDate()).padStart(2, '0');
                    let month = String(date.getMonth() + 1).padStart(2, '0');
                    let year = date.getFullYear();
                    return `${day}-${month}-${year}`;
                },
                canFilter: true,
            },
            {
                Header: "Amount",
                accessor: "amount",
                width: "100px",
                minWidth: "10px",
                canFilter: true,
            },
            {
                Header: "Bank Name",
                accessor: "bank_name",
                width: "100px",
                minWidth: "10px",
                canFilter: true,
            },
            {
                Header: "Party Name",
                accessor: "party_name",
                width: "250px",
                minWidth: "10px",
                canFilter: true,
            },
        ],
        []
    );

    const [data, setData] = React.useState([]);
    const [loading, setLoading] = React.useState(false);
    const [pageCount, setPageCount] = React.useState(0);
    const [pageState, setPageState] = React.useState({});
    const fetchIdRef = React.useRef(0);
    let download_ref = React.useRef(null);
    const sortIdRef = React.useRef(0);
    const [dateState, setDateState] = React.useState({
        date_from: new Date(),
        date_to: new Date(),
    });
    const [finalInput, setFinalInput] = React.useState({});

    const fetchData = React.useCallback(
        async ({ pageSize, pageIndex, sortBy, customFilters, dateObject }) => {
            const fetchId = ++fetchIdRef.current;
            setLoading(true);

            if (fetchId === fetchIdRef.current) {
                customFilters.created_from = String(
                    sessionObject.sessionVariables.branch_id
                );

                let newObject = { ...customFilters };

                const url = `${SERVER_URL}/report/get_extra_payment_report`;

                try {
                    let response = await fetch(url, {
                        method: "GET",
                        headers: {
                            Accept: "application/json",
                        },
                    });
                    let resp = await response.json();
                    let setObj = {};
                    if (fetchId == fetchIdRef.current) {
                        if (resp && Array.isArray(resp)) {
                            setData(resp);
                            setPageCount(Math.ceil(resp.length / pageSize));
                            setPageState({ ...pageState, ...setObj });
                        }
                        setLoading(false);
                    }
                } catch (error) {
                    if (fetchId === fetchIdRef.current) {
                        setLoading(false);
                    }
                }
            }
        },
        []
    );

    return (
        <div className="report-challan">
            <div className="form-header">Extra Payment Report</div>
            <div className="report-challan-table-container">
                <div className="report-field-row">
                    <div>
                        From Date:{" "}
                        <DatePicker
                            dateFormat="dd-MM-yyyy"
                            selected={dateState.date_from}
                            onChange={(date) =>
                                setDateState({
                                    ...dateState,
                                    ["date_from"]: date,
                                })
                            }
                        />
                    </div>
                    <div>
                        To Date:{" "}
                        <DatePicker
                            dateFormat="dd-MM-yyyy"
                            selected={dateState.date_to}
                            onChange={(date) =>
                                setDateState({
                                    ...dateState,
                                    ["date_to"]: date,
                                })
                            }
                        />
                    </div>
                </div>

                <ReportTable
                    columns={columns}
                    data={data}
                    fetchData={fetchData}
                    dateObject={dateState}
                    loading={loading}
                    pageCount={pageCount}
                />
            </div>
            {/* <div className="form-footer">
                <button style={{ display: "none" }}>
                    <ReactHTMLTableToExcel
                        id="test-table-xls-button"
                        className="download-table-xls-button"
                        table="challan_report_excel"
                        filename="tablexls"
                        sheet="tablexls"
                        buttonText="Download as XLS"
                        ref={(a) => (download_ref.current = a)}
                    />
                </button>
                <button
                    className="download-table-xls-button"
                    onClick={async () => {
                        setLoading(true);

                        let inputData = {
                            sort_fields: finalInput.sort_fields,
                            filter_fields: finalInput.filter_fields,
                            date_dict: finalInput.date_dict,
                        };

                        let response = await fetch(SERVER_URL + "/report/challan", {
                            method: "POST",
                            headers: {
                                Accept: "application/json",
                                "Content-Type": "application/json",
                            },
                            body: JSON.stringify(inputData),
                        });

                        let resp = await response.json();

                        excelData = resp.data;
                        setLoading(false);
                        download_ref.current.handleDownload();
                    }}
                >
                    Download as XLS
                </button>
                {ReportExcel.challan_report_excel(excelData, dateState)}
            </div> */}
        </div>
    );
}

export default ExtraPaymentReport;
