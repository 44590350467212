import React from "react";
import { SERVER_URL } from "../config/config.js";
import "./challanReport.css";
import "react-datepicker/dist/react-datepicker.css";
import ReportTable from "./ReportTable";

function UserPerformanceReport({ sessionObject }) {
    const columns = React.useMemo(
        () => [
            {
                Header: "Name",
                accessor: "name",
                width: "100px",
                minWidth: "10px",
                canFilter: true,
            },
            {
                Header: "User ID",
                accessor: "user_id",
                width: "100px",
                minWidth: "10px",
            },
            {
                Header: "Count",
                accessor: "count",
                width: "100px",
                minWidth: "10px",
            },
        ],
        []
    );

    const [data, setData] = React.useState([]);
    const [loading, setLoading] = React.useState(false);
    const [pageCount, setPageCount] = React.useState(0);

    const fetchData = React.useCallback(
        async ({ pageSize, pageIndex, customFilters }) => {
            setLoading(true);

            let inputData = {
                filter_fields: customFilters,
                paginate: { number_of_rows: pageSize, page_number: pageIndex + 1 },
            };

            let response = await fetch(SERVER_URL + "/report/user_report", {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(inputData),
            });
            let resp = await response.json();
            if (resp["data"] && "total_rows" in resp) {
                setData(resp["data"]);
                setPageCount(Math.ceil(resp["total_rows"] / pageSize));
            }
            setLoading(false);
        },
        []
    );

    return (
        <div className="report-challan">
            <div className="form-header">User Performance Report</div>
            <div className="report-challan-table-container">
                <ReportTable
                    columns={columns}
                    data={data}
                    fetchData={fetchData}
                    loading={loading}
                    pageCount={pageCount}
                />
            </div>
        </div>
    );
}

export default UserPerformanceReport;
