import React from 'react';
import UserPerformanceReport from '../UserPerformanceReport';

export default function ReportUserPerformance({ sessionObject }) {
    if (!sessionObject.sessionVariables["modules"].includes("report-user-performance")) {
        return;
    }
    return (
        <div className="page-challan">
            <UserPerformanceReport sessionObject={sessionObject} />
        </div>
    );
}
