import React, { useRef, useState } from "react";
import { SERVER_URL, USE_OVERLAY } from "../config/config";
import "./MarfatiyaWise.css";
import "./AutoSuggest.css";
import { dataObject, validate } from "../config/voucherLedger";
import useForm from "./useForm";
import LoadingOverlay from "react-loading-overlay";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import ReportExcel from "./ReportExcel.js";
let ex_data = [];

function VoucherLedger({ sessionObject }) {
    const [loading, setLoading] = useState(false);
    let variablesFromSession = {
        created_from: String(sessionObject.sessionVariables.branch_id),
        company_id: sessionObject.sessionVariables.company_id ?? "1",
        fYear: sessionObject.sessionVariables.financial_year,
    };
    const myForm = useForm(
        "VOUCHERLEDGER",
        validate,
        { ...dataObject, ...variablesFromSession },
        null
    );

    const download_ref = useRef(null);

    const voucherTypes = [
        { name: "J.V.", value: "1" },
        { name: "Cash", value: "2" },
    ];

    function formatDate(date) {
        var d = new Date(date),
            month = "" + (d.getMonth() + 1),
            day = "" + d.getDate(),
            year = d.getFullYear();
    
        if (month.length < 2) month = "0" + month;
        if (day.length < 2) day = "0" + day;
    
        return [day, month, year].join("-");
    }

    return (
        <div className="page-marfatiya-wise">
            <div className="mr-form-container">
                {USE_OVERLAY && (
                    <LoadingOverlay
                        active={myForm.overlay}
                        spinner
                        text="Loading your content..."
                        styles={{
                            wrapper: {
                                overflow: true ? "hidden" : "scroll",
                            },
                        }}
                    ></LoadingOverlay>
                )}
                <div className="form-header">Voucher Ledger</div>
                <div className="form-marfatiya-wise" style={{ boxShadow: "none" }}>
                    <div className="form-row">
                        <label className="form-label">From Voucher:</label>
                        <input
                            className="form-input"
                            type="number"
                            name="voucher_from"
                            value={myForm.pageState.voucher_from}
                            onChange={myForm.handleChange}
                            onKeyPress={(a) => {
                                if (a.key == "Enter") {
                                    myForm.makeFocusOnParticularField("voucher_to");
                                }
                            }}
                            ref={(a) => myForm.storeInputReferenceForSelect(a, "voucher_from")}
                        />
                    </div>
                    <div className="form-row">
                        <label className="form-label">To Voucher:</label>
                        <input
                            className="form-input"
                            type="number"
                            name="voucher_to"
                            value={myForm.pageState.voucher_to}
                            onChange={myForm.handleChange}
                            onKeyPress={(a) => {
                                if (a.key == "Enter") {
                                    myForm.makeFocusOnParticularField("voucher_type");
                                }
                            }}
                            ref={(a) => myForm.storeInputReferenceForSelect(a, "voucher_to")}
                        />
                    </div>
                    <div className="form-row">
                        <label className="form-label">Voucher Type:</label>
                        <select
                            name="voucher_type"
                            className="form-input"
                            value={myForm.pageState.voucher_type}
                            onChange={(e) => myForm.handleChangeForSelect(e, "voucher_type")}
                            ref={(a) => myForm.storeInputReferenceForSelect(a, "voucher_type")}
                            onKeyDown={(a) => {
                                if (a.key == "Enter") {
                                    myForm.makeFocusOnParticularField("excel_button");
                                }
                            }}
                        >
                            {voucherTypes.map((voucher) => <option value={voucher.value}>
                                {voucher.name}
                            </option>)}
                        </select>
                    </div>

                    <div className="form-footer" style={{ marginTop: "10px" }}>
                        <button style={{ display: "none" }}>
                            <ReactHTMLTableToExcel
                                id="test-table-xls-button"
                                className="download-table-xls-button"
                                table="voucher_ledger_excel"
                                filename="Voucher-ledger"
                                sheet="sheet1"
                                buttonText="Download as XLS"
                                ref={(a) => (download_ref.current = a)}
                            />
                        </button>
                        <button
                            className="download-table-xls-button"
                            onClick={async () => {
                                setLoading(true);
                                const fYear_fetch = JSON.parse(sessionStorage.getItem("financial_year_for_fetch")).financial_year_for_fetch;
                                
                                // TODO: change the api url, 
                                // add voucher_from, voucher_to, voucher_type in the payload
                                
                                let url = SERVER_URL + "/account_trans/search/" +
                                    "?voucher_type=jv" +
                                    "&branch_id=" + myForm.pageState.created_from +
                                    "&from_id=" + String(myForm.pageState.voucher_from) +
                                    "&to_id=" + String(myForm.pageState.voucher_to) +
                                    "&fyear=" + fYear_fetch +
                                    "&companyId=" + myForm.pageState.company_id;

                                let resp = await fetch(url);
                                let inputObject = await resp.json();

                                let newList = [];
                                let dummyObject = {};
                                let outputObj = {};
                                for (let i = 0; i < inputObject.length; i++) {
                                    if (i % 2 == 0) {
                                        dummyObject = {};
                                        dummyObject.debit = inputObject[i].debit;
                                        // dummyObject.credit = inputObject[i + 1].credit;
                                        dummyObject.credit = "";
                                        dummyObject.voucher_type = inputObject[i].voucher_type;
                                        dummyObject.consignor_id = inputObject[i].party_id,
                                        dummyObject.consignor_name = inputObject[i].party_name;
                                        dummyObject.amount = inputObject[i].debit;
                                        dummyObject.consignee_id = inputObject[i + 1].party_id;
                                        dummyObject.consignee_name = inputObject[i + 1].party_name;
                                        dummyObject.remarks = inputObject[i].remarks;
                                        dummyObject.cheque_no = inputObject[i].cheque_no;
                                        dummyObject.bank_name = inputObject[i].bank_name;
                                        dummyObject.unique_id = inputObject[i].unique_id;
                                        dummyObject.voucher_id = inputObject[i].voucher_id;
                                        dummyObject.created_from = inputObject[i].created_from;
                                        dummyObject.created_by = inputObject[i].created_by;
                                        dummyObject.created_from_name = inputObject[i].created_from_name;
                                        dummyObject.created_by_name = inputObject[i].created_by_name;
                                        dummyObject.account_id = inputObject[i].account_id;
                                        dummyObject.date = formatDate(inputObject[i].date);
                                        dummyObject.clearance_status = inputObject[i].clearance_status ?? inputObject[i + 1].clearance_status;
                                        dummyObject.clearance_date = inputObject[i].clearance_date ?? inputObject[i + 1].clearance_date;
                                        newList.push(dummyObject);
                                    }
                                }
                                outputObj.account_transaction_info = newList;
                                let total_amount = 0;
                                for (let i = 0; i < outputObj.account_transaction_info.length; i++) {
                                    total_amount += outputObj.account_transaction_info[i].amount
                                }
                                outputObj.total_amount = total_amount.toFixed(2)

                                console.log(outputObj);
                                ex_data = outputObj;

                                setLoading(false);
                                download_ref.current.handleDownload();
                            }}
                            ref={(a) => myForm.storeInputReferenceForSelect(a, "excel_button")}
                        >
                            Download as XLS
                        </button>
                        {ReportExcel.voucher_ledger_excel(
                            { 
                                data: ex_data,
                                voucher_from: myForm.pageState.voucher_from, 
                                voucher_type: myForm.pageState.voucher_type, 
                                voucher_to: myForm.pageState.voucher_to, 
                            }
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default VoucherLedger;
