import React from 'react';
import ExtraPaymentReport from '../ExtraPaymentReport';

export default function ReportExtraPayment({ sessionObject }) {
  if(!sessionObject.sessionVariables["modules"].includes("report-challan")){
    return;}
  return (
    <div className="page-challan">
    <ExtraPaymentReport sessionObject={sessionObject}/>
    </div>
  );
}
