import React from 'react';
import '../../App.css';
import MultipleJv from '../MultipleJvForm';

function MultipleJvPage({ sessionObject }) {
  if(!sessionObject.sessionVariables["modules"].includes("challan")){
    return;}
  return (
    <div className="page-challan">
      <MultipleJv sessionObject={sessionObject}/>
    </div>
  );
}

export default MultipleJvPage;