import { SERVER_URL } from "./config";

const groupNames = ["group-1"];

const NarrationMasterGroupInfo =  {
    "group-1": [
        {
          label: "Narration",
          className: "form-row",
          labelClassName: "form-label",
          inputClassName: "form-input",
          name: "name",
          type: "text",
          placeHolder: "1234",
          apiConfigKey: "getNarrationSuggestions",
          suggestionKeywordForFetchApiArgs: "name",
          suggestionChooseQueryKeyword: "id",
          suggestionKeywordExtra: "description",
          url: SERVER_URL + "/narration/filter/",
          suggestionKeyword: "name",
          backendOnClickName: "vehicle_no",
          toValidate: "true",
          apiCallRequiredOnGetValue: true,
          idClearanceNeeded: "item_id",
          onChangeIgnoreClearance: ["description"],
        },
      ],
}

const NarrationMasterDataObject = {
    id: "",
    name: "",
  };

  function NarrationMasterValidate(values) {
    let errors = {};
    return errors;
  }
  
  export { NarrationMasterGroupInfo, NarrationMasterDataObject, NarrationMasterValidate };
  