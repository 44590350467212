import React from "react";
import "../../App.css";
import NarrationMaster from "../NarrationMaster";

function NarrationMasterPage({ sessionObject }) {
  console.log("SESION OBJ");
  if(!sessionObject.sessionVariables["modules"].includes("narration-master")){
    return ;}
  return (
    <div className="page-vehicle">
      <NarrationMaster sessionObject={sessionObject} />
    </div>
  );
}

export default NarrationMasterPage;