import React from "react";
import { SERVER_URL } from "../config/config.js";
import "./challanReport.css";
import "react-datepicker/dist/react-datepicker.css";
import ReportTable from "./ReportTable.js";
import ReportExcel from "./ReportExcel.js";
import ReactHTMLTableToExcel from "react-html-table-to-excel";

function CompanyReport({ sessionObject }) {
    const columns = React.useMemo(
        () => [
            {
                Header: "Name",
                accessor: "name",
                width: "230px",
                minWidth: "100px",
                canFilter: true,
            },
            {
                Header: "GST",
                accessor: "gst",
                width: "230px",
                minWidth: "100px",
                canFilter: true,
            },
            {
                Header: "Group Name",
                accessor: "group_name",
                width: "230px",
                minWidth: "100px",
                canFilter: true,
            },
            {
                Header: "Parent Company Name",
                accessor: "parent_company_name",
                width: "230px",
                minWidth: "100px",
                canFilter: true,
            },
            {
                Header: "SubGroup Name",
                accessor: "subgroup_name",
                width: "230px",
                minWidth: "100px",
                canFilter: true,
            },
        ],
        []
    );

    const [data, setData] = React.useState([]);
    const [loading, setLoading] = React.useState(false);
    const [pageCount, setPageCount] = React.useState(0);
    const [finalInput, setFinalInput] = React.useState({});
    const download_ref = React.useRef(null);
    let ex_data = [];
    let newObject = {name:"b"}
    
    const fetchData = React.useCallback(
        async ({ pageSize, pageIndex, customFilters, dateObject,sortBy }) => {
            for (let key in customFilters){
                if (customFilters[key]== null){
                continue
                }
                if (customFilters[key].toLowerCase() == "none"){
                customFilters[key] = null
                }
            }
            setLoading(true);
            newObject = {...newObject,...customFilters}
            let inputData = {
                filter_fields: newObject,
                sort_fields:sortBy,
                paginate : {number_of_rows: pageSize, page_number: pageIndex + 1} ,
            };
            setFinalInput(inputData);
            let response = await fetch(SERVER_URL + "/report/company_master_report", {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(inputData),
            });
            let resp = await response.json();
            if (resp["data"] && "total_rows" in resp) {
                setData(resp["data"]);
                setPageCount(Math.ceil(resp["total_rows"] / pageSize));
                ex_data = resp["data"]
            }else {
                ex_data = []
            }
            setLoading(false);
        },
        []
);

    const fetchDataDebounced = React.useMemo(
        () => _.debounce(fetchData, 500),
        []
      );

    return (
        <div className="report-challan">
            <div className="form-header">Company Master Report</div>
            <div className="report-challan-table-container">
                <ReportTable
                    columns={columns}
                    data={data}
                    fetchData={fetchDataDebounced}
                    loading={loading}
                    pageCount={pageCount}
                    title={"Company Master Report"}
                />
            </div>
            <div className="form-footer">
            <button style={{ display: "none" }}>
            <ReactHTMLTableToExcel
              id="test-table-xls-button"
              className="download-table-xls-button"
              table="bilty_report_excel"
              filename="tablexls"
              sheet="tablexls"
              buttonText="Download as XLS"
              ref={(a) => (download_ref.current = a)}
            />
          </button>
                <button
                 className="download-table-xls-button"
                 onClick={async () => {
                            setLoading(true);

                            let inputData = {
                                filter_fields: finalInput.filter_fields,
                                paginate : finalInput.paginate ,
                            };

                            let response = await fetch(SERVER_URL + "/report/company_master_report", {
                                method: "POST",
                                headers: {
                                    Accept: "application/json",
                                    "Content-Type": "application/json",
                                },
                                body: JSON.stringify(inputData),
                            });
                            let resp = await response.json();
                            if (resp["data"] && "total_rows" in resp) {
                                setData(resp["data"]);
                                // setPageCount(Math.ceil(resp["total_rows"] / pageSize));
                                ex_data = resp["data"]
                            }
                            setLoading(false)
                    
                    download_ref.current.handleDownload();
                    } }
                 >
                Download as XLS
                </button>
            {ReportExcel.company_data(
            { columns },
            { data },
            { fetchData },
            { loading },
            { pageCount },
          )}

            </div>
        </div>
    );
}

export default CompanyReport;
