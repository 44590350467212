const componentMapping = {
  'bilty': () => import('../components/FormSignup'),
  'challan': () => import('../components/ChallanForm'),
  'mr': () => import('../components/MRForm'),
  'bilty-inquiry': () => import('../components/BiltyInquiry'),
  'mr-inquiry': () => import('../components/mrInquiry'),
  'inward': () => import('../components/ChallanInwardForm'),
  'biltystatement': () => import('../components/BiltyStatementForm'),
  'mrstatement': () => import('../components/MrStatementForm'),
  'consignorbilling': () => import('../components/ConsignorBillingForm'),
  'pod_statement': () => import('../components/PodStatementForm'),
  'crossingbilling': () => import('../components/CrossingBillingForm'),
  'tripbhada': () => import('../components/TripBhadaForm'),
  'pod_challan': () => import('../components/PodChallanForm'),
  'pod_challan_inward': () => import('../components/PodChallanInwardForm'),
  'crossingOutward': () => import('../components/CrossingOutward'),
  'report-bilty': () => import('../components/BiltyReport'),
  'report-challan': () => import('../components/ChallanReport'),
  'ewbextensionreport': () => import('../components/EwbExtensionReport'),
  'ackpendingpartyreport': () => import('../components/AckPendingPartyReport'),
  'general_rate_master': () => import('../components/GeneralRateMaster'),
  'account_master': () => import('../components/AccountMasterForm'),
  'station_master': () => import('../components/StationMasterForm'),
  'item_master': () => import('../components/ItemMasterForm'),
  'vehicle': () => import('../components/VehicleForm'),
  'party_rate_master': () => import('../components/PartyRateMaster'),
  'trip': () => import('../components/Trip'),
  'crossingInward': () => import('../components/crossingInward'),
  'branch-sel': () => import('../components/BranchSelector'),
  'account-transaction?voucher_type=jv': () => import('../components/VoucherLedger'),

  'account-report': () => import('../components/AccountReport'),
  'marfatiya-wise': () => import('../components/MarfatiyaWise'),
  'report-trip': () => import('../components/TripReport'),
  'brokerage': () => import('../components/BrokerageForm'),
  'brokerage-summary': () => import('../components/BrokerageSummary'),
  'bilty-ack': () => import('../components/BiltyAcknowledgement'),
  'admin-report-bilty': () => import('../components/AdminBiltyReport'),
  'report-vehicleregister': () => import('../components/VehicleRegisterReport'),
  'report-vehicle': () => import('../components/VehicleReport'),
  // 'vehicleregister': () => import('../components/VehicleRegisterComponent'),
  // 'account-transaction?voucher_type=cp': () => import('../components/CashPaymentComponent'),
  // 'account-transaction?voucher_type=cr': () => import('../components/CashReceiptComponent'),
  // 'account-transaction?voucher_type=br': () => import('../components/BankReceiptComponent'),
  // 'account-transaction?voucher_type=bp': () => import('../components/BankPaymentComponent'),
  // 'account-transaction': () => import('../components/AccountTransactionComponent'),
  // 'report-bill': () => import('../components/FreightBillReportComponent'),
  // 'bill-paid': () => import('../components/BillEntryBillPaidComponent'),
  'crossing-outward-report': () => import('../components/CrossingOutwardReport'),
  // 'outstanding-partywise': () => import('../components/OutstandingPartywiseComponent'),
  // 'pl-report': () => import('../components/ProfitLossReportComponent'),
  // 'tds-report': () => import('../components/TdsReportComponent'),
  // 'vinod-report': () => import('../components/VinodReportComponent'),
  // 'opening-balance': () => import('../components/OpeningBalanceComponent'),
  // 'bank-clearance': () => import('../components/BankClearanceComponent'),
  // 'report-mr': () => import('../components/MrReportComponent'),
  // 'crossing-inward-report': () => import('../components/CrossingInwardReportComponent'),
  // 'signup': () => import('../components/UserSignupComponent'),
  // 'change-password': () => import('../components/ChangePasswordComponent'),
  // 'group_master': () => import('../components/GroupMasterComponent'),
  // 'subgroup_master': () => import('../components/SubgroupMasterComponent'),
  // 'separate-ewb': () => import('../components/LocalDeliveryComponent'),
  // 'edit-role-id': () => import('../components/EditRoleIdComponent'),
  // 'consignor-auto-billing': () => import('../components/PartyAutoBillingComponent'),
  // 'mr-pending-amount': () => import('../components/MrPendingAmountComponent'),
  // 'report-mr-pending-amount': () => import('../components/MrPendingAmountReportComponent'),
  // 'report-paid-statement': () => import('../components/BiltyPaidStatementReportComponent'),
  // 'report-mr-statement': () => import('../components/MrStatementReportComponent'),
  // 'mail-master': () => import('../components/MailMasterComponent'),
  // 'report-pod-statement': () => import('../components/PodPartyStatementComponent'),
  // 'report-user': () => import('../components/UserReportComponent'),
  // 'manage-dashboard': () => import('../components/ManageDashboardComponent'),
  // 'report-pending-partb': () => import('../components/PendingPartBComponent'),
  // 'hisab': () => import('../components/HisabComponent'),
  // 'pod-auto-statement': () => import('../components/PodAutoStatementComponent'),
  // 'fleet-management': () => import('../components/FleetManagementComponent'),
  // 'report-fleet': () => import('../components/FleetReportComponent'),
  // 'stock-outward': () => import('../components/StockOutwardComponent'),
  // 'stock-inward': () => import('../components/StockInwardComponent'),
  // 'narration-master': () => import('../components/NarrationMasterComponent'),
  // 'multi-jv?voucher_type=jv': () => import('../components/MultipleJvComponent'),
  // 'multi-jv': () => import('../components/MultipleJvComponent'),
  // 'subgroup-report': () => import('../components/SubgroupReportComponent'),
  // 'analysis-report': () => import('../components/AnalysisReportComponent'),
  // 'station-wise-summary': () => import('../components/StationWiseSummaryComponent'),
  // 'extra-payment': () => import('../components/ExtraPaymentComponent'),
  // 'report-extra-payment': () => import('../components/ExtraPaymentReportComponent'),
  // 'declarationform': () => import('../components/DeclarationFormComponent'),
};

export default componentMapping;
