import React from 'react';
import SummaryStationWise from '../SummaryStationWise';

export default function StationWiseSummary({ sessionObject }) {
    if (!sessionObject.sessionVariables["modules"].includes("station-wise-summary")) {
        return;
    }
    return (
        <div className="page-challan">
            <SummaryStationWise sessionObject={sessionObject} />
        </div>
    );
}