import React from "react";
import "../../App.css";
import AnalysisReport from "../AnalysisReport";

function AnalysisReportPage({ sessionObject }) {
    if (!sessionObject.sessionVariables["modules"].includes("analysis-report")) {
        return;
    }
    return (
        <div className="page-vehicle">
            <AnalysisReport sessionObject={sessionObject} />
        </div>
    );
}

export default AnalysisReportPage;
