import React, { useState, useRef, useEffect } from "react";
import { SERVER_URL } from "../config/config";
import "./MarfatiyaWise.css";
import print from "print-js";
import "./popup.css";
import Popup from "reactjs-popup";

function formatDate(date) {
  var d = new Date(date),
    month = "" + (d.getMonth() + 1),
    day = "" + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  return [year, month, day].join("-");
}

function ExtraPayment({ sessionObject }) {
  const contentStyle = {
    maxWidth: "600px",
    width: "90%",
  };
  const popupInfo = {
    error_header: "Error ",
    success_header: "Success",
  };
  var nowDate = new Date();
  var date = nowDate.getFullYear() + "-" + String(nowDate.getMonth() + 1).padStart(2, "0") + "-" + nowDate.getDate();
  const [pageState, setPageState] = useState({
    payment_no: "",
    amount: "",
    bank_name: "",
    party_name: "",
    created_date: formatDate(new Date()),
    created_date_time: new Date().toISOString(),
  });
  let refStoreObject = useRef({});

  const [previousPageMode, setPreviousPageMode] = useState("");
  const [pageMode, setPageMode] = useState("");
  const [popupError, setPopupError] = useState("");

  useEffect(() => {
    setPageState((prevState) => ({
      ...prevState,
      created_date: formatDate(Date.now()),
      created_date_time: new Date().toISOString(),
    }));
  }, []);

  const handleChange = async (e) => {
    const { name, value } = e.target;

    if (name === "created_date") {
      const date = new Date(value);
      const dateTime = new Date(date.setHours(new Date(pageState.created_date_time).getHours(), new Date(pageState.created_date_time).getMinutes(), new Date(pageState.created_date_time).getSeconds()));
      setPageState({
        ...pageState,
        [name]: value,
        created_date_time: dateTime.toISOString(),
      });
    } else {
      setPageState({
        ...pageState,
        [name]: value,
      });
    }
  };

  const makeFocusOnParticularField = (fieldName) => {
    refStoreObject.current[fieldName].focus();
  };

  const storeInputReferenceForSelect = (refObject, fieldName) => {
    if (refObject == null) {
      return;
    }
    refStoreObject.current[fieldName] = refObject;
  };

  const linkBilty = async (e) => {
    if (e.key == "Enter") {
      try {
        const url = SERVER_URL + `/extra_payment?payment_id=${pageState.payment_no}`;
        const response = await fetch(url);

        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const temp_response = await response.json();

        if ("created_date" in temp_response) {
          temp_response.created_date = formatDate(temp_response.created_date);
        }

        setPageState({
          ...pageState,
          ...temp_response,
        });
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }
  };

  const handleDelete = async () => {
    let url = SERVER_URL + `/extra_payment?id=${pageState.payment_no}`;

    try {
      const response = await fetch(url, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (response.ok) {
        const result = await response.json();
        window.location.reload()
        console.log('Delete successful:', result);
      } else {
        console.error('Delete failed:', response.statusText);
      }
    } catch (error) {
      console.error('Error occurred during delete request:', error);
    }
  };

  const handlePrint = async () => {
    let url;
    let method;
  
    if (pageState.payment_no) {
      url = SERVER_URL + "/extra_payment?id=" + pageState.payment_no;
      method = "PUT";
    } else {
      url = SERVER_URL + "/extra_payment";
      method = "POST";
    }
  
    try {
      const response = await fetch(url, {
        method: method,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          amount: pageState.amount,
          created_date: pageState.created_date_time,
          bank_name: pageState.bank_name,
          party_name: pageState.party_name,
        }),
      });
  
      if (!response.ok) {
        return;
      }
  
      setPageMode("submitted");
      const resp = await response.json();
    } catch (error) {
      console.error("Error occurred during print request:", error);
    }
  };
  

  return (
    <div className="page-marfatiya-wise">
      <div className="mr-form-container">
        <div>
          <Popup
            open={pageMode == "submitted" || pageMode == "error"}
            modal
            contentStyle={contentStyle}
            closeOnDocumentClick={false}
          >
            {(close) => (
              <div className="pop-up-container">
                <div className="pop-up-header">
                  {" "}
                  {pageMode == "submitted" ? (
                    <div>{popupInfo.success_header}</div>
                  ) : (
                    <div>{popupInfo.error_header}</div>
                  )}
                  <div>
                    <a className="pop-up-close btn" onClick={close}>
                      &times;
                    </a>
                  </div>
                </div>
                {pageMode == "submitted" ? (
                  <div className="pop-up-content">
                    {popupInfo.success_title}
                    <br />
                    <div className="pop-up-fields">
                      <div className="pop-up-field">
                        <div className="pop-up-field-label">
                          {popupInfo.field_label_success}
                        </div>
                        <div className="pop-up-field-value">
                          {pageState[popupInfo.field_name_success]}
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="pop-up-content">
                    {popupInfo.error_title}
                    <br />
                    <div className="pop-up-fields">
                      <div className="pop-up-field">
                        <div className="pop-up-field-label">
                          {popupInfo.field_label_error}
                        </div>
                        <div className="pop-up-field-value">
                          {popupError}
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                <div className="pop-up-actions">
                  <button
                    className="pop-up-button"
                    onClick={() => {
                      if (pageMode == "submitted") {
                        setPageMode("write");
                        window.location.reload();
                        close();
                      } else {
                        if (previousPageMode != "") {
                          setPageMode(previousPageMode);
                          setPreviousPageMode("");
                        } else {
                          setPageMode("write");
                        }
                        close();
                      }
                    }}
                  >
                    Okay
                  </button>
                </div>
              </div>
            )}
          </Popup>
        </div>
        <div className="form-header">Extra Payment</div>
        <div className="form-marfatiya-wise">
          <div className="form-row">
            <label className="form-label">Payment No. </label>
            <input
              className="form-input"
              type="input"
              name="payment_no"
              placeholder=""
              value={pageState.payment_no}
              onChange={handleChange}
              onKeyPress={linkBilty}
            />
          </div>
          <div className="form-row">
            <label className="form-label">Amount </label>
            <input
              className="form-input"
              type="input"
              name="amount"
              placeholder=""
              value={pageState.amount}
              onChange={handleChange}
            />
          </div>
          <div className="form-row">
            <label className="form-label">Created Date: </label>
            <input
              className="form-input"
              type="date"
              name="created_date"
              placeholder=""
              value={pageState.created_date}
              onChange={handleChange}
            />
          </div>
          <div className="form-row">
            <label className="form-label">Bank Name </label>
            <input
              className="form-input"
              name="bank_name"
              placeholder=""
              value={pageState.bank_name}
              onChange={handleChange}
              ref={(a) => storeInputReferenceForSelect(a, "bank_name")}
            />
          </div>
          <div className="form-row">
            <label className="form-label">Party Name </label>
            <input
              className="form-input"
              name="party_name"
              placeholder=""
              value={pageState.party_name}
              onChange={handleChange}
              ref={(a) => storeInputReferenceForSelect(a, "party_name")}
              onKeyPress={(a) => {
                if (a.key == "Enter") {
                  a.preventDefault();
                  makeFocusOnParticularField("save_button");
                }
              }}
            />
          </div>
          <div className="form-actions">
            <button onClick={handlePrint}
              ref={(a) => storeInputReferenceForSelect(a, "save_button")}
            > Save</button>
            <button onClick={handleDelete}
              ref={(a) => storeInputReferenceForSelect(a, "delete_button")}
            > Delete</button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ExtraPayment;
