import React, { useRef, useState } from "react";
import { SERVER_URL, USE_OVERLAY } from "../config/config";
import "./MarfatiyaWise.css";
import "./AutoSuggest.css";
import { dataObject, getPartyInput, getStationInput, reportTypes, showParty, showStations, validate } from "../config/analysisReport";
import useForm from "./useForm";
import LoadingOverlay from "react-loading-overlay";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import ReportExcel from "./ReportExcel.js";
import { ANALYSIS_REPORT } from "../utils/ScreenNames";
import { analysisReportApiConfig } from "../config/apiConfig";
let ex_data = [];

function AnalysisReport({ sessionObject }) {
    const [loading, setLoading] = useState(false);
    let variablesFromSession = {
        created_from: String(sessionObject.sessionVariables.branch_id),
        company_id: sessionObject.sessionVariables.company_id ?? "1",
        fYear: sessionObject.sessionVariables.financial_year,
    };
    const myForm = useForm(
        ANALYSIS_REPORT,
        validate,
        { ...dataObject, ...variablesFromSession },
        analysisReportApiConfig
    );

    const download_ref = useRef(null);

    const checkVisibilityCondition = (field) => {
        let ans = false;
        if(field == "station_name") {
            showStations.forEach(reportNo => {
                if(reportNo == myForm.pageState.report_type) {
                    ans = true;
                }
            })
        }
        else if(field == "party_name") {
            showParty.forEach(reportNo => {
                if(reportNo == myForm.pageState.report_type) {
                    ans = true;
                }
            })
        }
        return ans;
    }

    return (
        <div className="page-marfatiya-wise">
            <div className="mr-form-container">
                {loading && (
                    <LoadingOverlay
                        active={myForm.overlay}
                        spinner
                        text="Loading your content..."
                        styles={{
                            wrapper: {
                                overflow: true ? "hidden" : "scroll",
                            },
                        }}
                    ></LoadingOverlay>
                )}
                <div className="form-header">Analysis Report</div>
                <div className="form-marfatiya-wise" style={{ boxShadow: "none" }}>
                    <div className="form-row">
                        <label className="form-label">Report Type:</label>
                        <select
                            name="report_type"
                            className="form-input"
                            value={myForm.pageState.report_type}
                            onChange={(e) => myForm.handleChangeForSelect(e, "report_type")}
                            ref={(a) => myForm.storeInputReferenceForSelect(a, "report_type")}
                            onKeyDown={(a) => {
                                if (a.key == "Enter") {
                                    a.preventDefault();
                                    myForm.makeFocusOnParticularField("date_from");
                                }
                            }}
                        >
                            {reportTypes.map((report) => <option value={report.value}>
                                {report.name}
                            </option>)}
                        </select>
                    </div>
                    <div className="form-row">
                        <label className="form-label">Date From: </label>
                        <input
                            className="form-input"
                            type="date"
                            name="date_from"
                            value={myForm.pageState.date_from}
                            onChange={myForm.handleChange}
                            onKeyPress={(a) => {
                                if (a.key == "Enter") {
                                    myForm.makeFocusOnParticularField("date_to");
                                }
                            }}
                            ref={(a) => myForm.storeInputReferenceForSelect(a, "date_from")}
                        />
                    </div>

                    <div className="form-row">
                        <label className="form-label">Date To: </label>
                        <input
                            className="form-input"
                            type="date"
                            name="date_to"
                            placeholder=""
                            value={myForm.pageState.date_to}
                            onChange={myForm.handleChange}
                            onKeyPress={(a) => {
                                if (a.key == "Enter") {
                                    if(showStations.indexOf(myForm.pageState.report_type) != -1) {
                                        myForm.makeFocusOnParticularField("station_name");
                                    }
                                    else {
                                        myForm.makeFocusOnParticularField("excel_button");
                                    }
                                }
                            }}
                            ref={(a) => myForm.storeInputReferenceForSelect(a, "date_to")}
                        />
                    </div>

                    {checkVisibilityCondition("station_name") && getStationInput(myForm)}
                    {checkVisibilityCondition("party_name") && getPartyInput(myForm)}

                    <div className="form-footer" style={{ marginTop: "10px" }}>
                        <button style={{ display: "none" }}>
                            <ReactHTMLTableToExcel
                                id="test-table-xls-button"
                                className="download-table-xls-button"
                                table="analysis_report_excel"
                                filename="analysis_report"
                                sheet="sheet1"
                                buttonText="Download as XLS"
                                ref={(a) => (download_ref.current = a)}
                            />
                        </button>
                        <button
                            className="download-table-xls-button"
                            onClick={async () => {
                                setLoading(true);
                                const url = SERVER_URL + "/bilty/source";

                                let dataToSend = {
                                    date_from: myForm.pageState.date_from,
                                    date_to: myForm.pageState.date_to,
                                    report_type: myForm.pageState.report_type,
                                }

                                if (myForm.pageState.report_type == "2" || myForm.pageState.report_type == "5"){
                                    dataToSend.branch_id = myForm.pageState.station_id
                                }
                                if (myForm.pageState.report_type == "3" || myForm.pageState.report_type == "6"){
                                    dataToSend.branch_id = myForm.pageState.station_id
                                    dataToSend.consignor_id = myForm.pageState.consignor_id
                                }

                                const response = await fetch(url, {
                                  method: "POST",
                                  headers: {
                                    Accept: "application/json",
                                    "Content-Type": "application/json",
                                  },
                                  body: JSON.stringify(dataToSend),
                                });
                                const resp = await response.json();
                                if (resp){
                                    ex_data = resp;
                                }

                                setLoading(false);
                                download_ref.current.handleDownload();
                            }}
                            ref={(a) => myForm.storeInputReferenceForSelect(a, "excel_button")}
                        >
                            Download as XLS
                        </button>
                        {ReportExcel.analysis_excel(
                            {
                                data: ex_data,
                                report_type: myForm.pageState.report_type,
                                date_from: myForm.pageState.date_from,
                                date_to: myForm.pageState.date_to,
                            }
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AnalysisReport;
