import Autosuggest from "react-autosuggest";
import { SERVER_URL } from "./config";

function formatDate(date) {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2)
        month = '0' + month;
    if (day.length < 2)
        day = '0' + day;

    return [year, month, day].join('-');
}

const reportTypes = [
    { name: "Booking Analysis Global", value: "1" },
    { name: "Booking Analysis Station Wise", value: "2" },
    { name: "Booking Analysis Party Wise", value: "3" },
    { name: "Inward Analysis Global", value: "4" },
    { name: "Inward Analysis Station Wise", value: "5" },
    // { name: "inward Analysis Party Wise", value: "6" },
];

const showStations = ["2", "3", "5", "6"];
const showParty = ["3", "6"];

const dataObject = {
    report_type: "1",
    date_from: formatDate(new Date()),
    date_to: formatDate(new Date()),
    station_name: "",
    station_id: "",
    consignor_name: "",
    consignor_id: "",
};

function validate(values) {
    let errors = {};

    function validator(value, regexp) {
        var regex = new RegExp(regexp);
        if (values.hasOwnProperty(value)) {
            if (!values[value].trim()) {
                errors[value] = "Required Field";
            } else if (!regex.test(values[value])) {
                errors[value] = "Validation Error";
            } else {
                errors[value] = "";
            }
        }
    }
    for (let group_name of groupNames) {
        for (let i = 0; i < groupInfo[group_name].length; i++) {
            let field_object = groupInfo[group_name][i];
            if (field_object.toValidate) {
                validator(field_object.name, field_object.regExpValidation);
            }
        }
    }
    return errors;
}

let stationInfo = {
    label: "Station From",
    className: "form-row",
    labelClassName: "form-label",
    inputClassName: "form-input",
    name: "station_name",
    type: "text",
    placeHolder: "",
    apiConfigKey: "getCitySuggestions",
    url: SERVER_URL + "/branch/",
    suggestionKeyword: "name",
    suggestionKeywordForFetchApiArgs: "name",
    suggestionChooseQueryKeyword: "branch_id",
    apiCallRequiredOnGetValue: true,
    suggestionSchema: {
        branch_id: "station_id",
        name: "station_name",
    },
    toValidate: true,
    regExpValidation: "[a-zA-z]",
    keyboardNavigationMap: {
        conditionalNav: true,
        conditionalNavFunct: (pageState) => {
            let ans = "excel_button";
            showParty.forEach(reportNo => {
                if (reportNo == pageState.report_type) {
                    ans = "consignor_name";
                }
            })
            return ans;
        },
    },
    valueVerificationFromSuggestionNeeded: true,
    valueVerificationCompulsionField: "station_id",
};
let partyInfo = {
    label: "Party Name",
    className: "form-row",
    labelClassName: "form-label",
    inputClassName: "form-input",
    name: "consignor_name",
    type: "text",
    placeHolder: "",
    apiConfigKey: "getConsignorSuggestions",
    url: SERVER_URL + "/consignor/",
    suggestionKeyword: "consignor_name",
    suggestionKeywordForFetchApiArgs: "name",
    suggestionChooseQueryKeyword: "consignor_id",
    apiCallRequiredOnGetValue: true,
    suggestionSchema: {
        consignor_id: "consignor_id",
        consignor_name: "consignor_name",
    },
    toValidate: true,
    regExpValidation: "[a-zA-z]",
    keyboardNavigationMap: {
        Enter: "excel_button",
    },
    valueVerificationFromSuggestionNeeded: true,
    valueVerificationCompulsionField: "consignor_id",
};

const getStationInput = (myForm) => {
    return <div className="form-row">
        <label className="form-label"> {stationInfo.label} </label>
        <Autosuggest
            id={stationInfo["name"]}
            suggestions={(myForm.suggestions != undefined) ? myForm.suggestions : ""}
            onSuggestionsFetchRequested={(a) =>
                myForm.onSuggestionsFetchRequested(a, (b) =>
                    myForm.suggestionFetchApi(stationInfo, b)
                )
            }
            onSuggestionsClearRequested={() =>
                myForm.onSuggestionsClearRequested(stationInfo)
            }
            getSuggestionValue={(suggestion) =>
                suggestion[stationInfo.suggestionKeyword]
            }
            onSuggestionSelected={(a, b) =>
                myForm.getSuggestionValue(
                    b.suggestion,
                    stationInfo,
                    myForm.performSuggestions,
                    myForm.updatePageStateForGetSuggestion
                )
            }
            renderSuggestion={(a) =>
                myForm.renderSuggestion(a, stationInfo)
            }
            highlightFirstSuggestion={true}
            ref={(a) => myForm.storeInputReference(a, false)}
            inputProps={{
                value: String(myForm.pageState[stationInfo["name"]]),
                onChange: (a, b) => {
                    myForm.onChangeAutoSuggest(a, b, stationInfo);
                },
                onBlur: () => {
                    stationInfo["toValidate"]
                        ? myForm.onblurValidator(stationInfo)
                        : {};
                },
                onKeyPress: (a) =>
                    myForm.onKeyPressForKeyNav(a, stationInfo, myForm.pageState),
            }}
        />
    </div>;
}
const getPartyInput = (myForm) => {
    return <div className="form-row">
        <label className="form-label"> {partyInfo.label} </label>
        <Autosuggest
            id={partyInfo["name"]}
            suggestions={(myForm.suggestions != undefined) ? myForm.suggestions : ""}
            onSuggestionsFetchRequested={(a) =>
                myForm.onSuggestionsFetchRequested(a, (b) =>
                    myForm.suggestionFetchApi(partyInfo, b)
                )
            }
            onSuggestionsClearRequested={() =>
                myForm.onSuggestionsClearRequested(partyInfo)
            }
            getSuggestionValue={(suggestion) =>
                suggestion[partyInfo.suggestionKeyword]
            }
            onSuggestionSelected={(a, b) =>
                myForm.getSuggestionValue(
                    b.suggestion,
                    partyInfo,
                    myForm.performSuggestions,
                    myForm.updatePageStateForGetSuggestion
                )
            }
            renderSuggestion={(a) =>
                myForm.renderSuggestion(a, partyInfo)
            }
            highlightFirstSuggestion={true}
            ref={(a) => myForm.storeInputReference(a, false)}
            inputProps={{
                value: String(myForm.pageState[partyInfo["name"]]),
                onChange: (a, b) => {
                    myForm.onChangeAutoSuggest(a, b, partyInfo);
                },
                onBlur: () => {
                    partyInfo["toValidate"]
                        ? myForm.onblurValidator(partyInfo)
                        : {};
                },
                onKeyPress: (a) =>
                    myForm.onKeyPressForKeyNav(a, partyInfo),
            }}
        />
    </div>;
}

export {
    dataObject,
    validate,
    reportTypes,
    getStationInput,
    showStations,
    showParty,
    getPartyInput
};
