import React from "react";
import { UTILITY_SERVER_URL } from "../config/config";
import './ResetPassword.css';
import print from 'print-js';

function DeclarationForm() {
    const handleDownload = async () => {
        const endpointUrl = `${UTILITY_SERVER_URL}/bilty/print/`;
        const payload = {
            "client_name": "rcc",
            "file_name": "declarationform"
        };

        try {
            const response = await fetch(endpointUrl, {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(payload)
            });

            if (!response.ok) {
                console.error("Failed to send the request.");
                return;
            }

            const blob = await response.blob();
            const blobUrl = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.style.display = 'none';
            a.href = blobUrl;
            a.download = 'declarationform.pdf';
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(blobUrl);
        } catch (error) {
            console.error("Error during fetch:", error);
        }
    };

    const handlePrint = async () => {
        const endpointUrl = `${UTILITY_SERVER_URL}/bilty/print/`;
        const payload = {
            "client_name": "rcc",
            "file_name": "declarationform"
        };

        try {
            const response = await fetch(endpointUrl, {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(payload)
            }).then((r) => r.blob());
            print({
                printable: URL.createObjectURL(response),
                type: "pdf",
                showModal: false,
            });;

            if (!response.ok) {
                console.error("Failed to send the request.");
                return;
            }
        } catch (error) {
            console.error("Error during fetch:", error);
        }
    };

    return (
        <div className='page-marfatiya-wise'>
            <div className='signup-form-container'>
                <div className="form-header"> Declaration Form</div>
                <div className="cp-wrapper">
                    <div>
                        <button
                            onClick={handleDownload}
                            id="printButton"
                            name="printButton"
                            type="button"
                        >
                            Download Declaration
                        </button>
                        <button
                            onClick={handlePrint}
                            id="printButton"
                            name="printButton"
                            type="button"
                        >
                            Print Declaration
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default DeclarationForm;
