import React from 'react'
import CompanyReport from '../CompanyReport'

export default function ReportCompany({sessionObject }) {
    if (!sessionObject.sessionVariables["modules"].includes("report-company")) {
        return;
    }
    return (
        <div className="page-challan">
            <CompanyReport sessionObject={sessionObject} />
        </div>
    );
}
