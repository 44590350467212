import React, { useEffect, useState } from 'react';
import '../../App.css';
import CardGrid from "../CardGrid.js";
import { SERVER_URL } from '../../config/config.js';

export default function Home({ sessionObject }) {
  const [uniqueStations, setUniqueStations] = useState([]);
  const [stationWeights, setStationWeights] = useState({});

  useEffect(() => {
    const payload = {
      date_dict: {
        date_from: `20${sessionObject.sessionVariables.financial_year.split('-')[0]}-04-01T00:00:01.001Z`,
        date_to: new Date().toISOString()
      },
      filter_fields: {
        created_from: String(sessionObject.sessionVariables.branch_id),
        owned_by: String(sessionObject.sessionVariables.branch_id),
        companyId: "1",
        fyear: sessionObject.sessionVariables.financial_year
      },
    };

    fetch(`${SERVER_URL}/report/`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(payload)
    })
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok ' + response.statusText);
        }
        return response.json();
      })
      .then(data => {
        extractUniqueStations(data.data);
      })
      .catch(error => {
        console.error('There was a problem with the fetch operation:', error);
      });
  }, []);

  const extractUniqueStations = (data) => {
    const branchName = sessionObject.sessionVariables.branch_name;
    const stationSet = new Set();
    const weights = {};

    data.forEach(item => {
      if (item.station_from_name === branchName) {
        const station = item.station_to_name;
        stationSet.add(station);

        if (!weights[station]) {
          weights[station] = 0;
        }
        weights[station] += item.charge_weight || 0;
      }
    });
  
    const sortedStations = Array.from(stationSet).sort();
  
    const sortedWeights = {};
    sortedStations.forEach(station => {
      sortedWeights[station] = `${weights[station]} kg`;
    });
  
    setUniqueStations(sortedStations);
    setStationWeights(sortedWeights);
  };

  return (
    <div className="home">
      <CardGrid />
      <div className="notification-holder">
        <marquee className="notification-bar" behavior="scroll" direction="left" scrollamount="6">
          {uniqueStations.length > 0 && uniqueStations.map(station => (
            <span key={station} className="station-item">
              {station}: {stationWeights[station]}
            </span>
          ))}
        </marquee>
      </div>
    </div>
  );
}
