import React from 'react';
import '../../App.css';
import DeclarationForm from '../DeclarationForm';

function Declaration({ sessionObject }) {
  if(!sessionObject.sessionVariables["modules"].includes("declarationform")){
    return;}
  return (
    <div className="page-challan">
      <DeclarationForm sessionObject={sessionObject}/>
    </div>
  );
}

export default Declaration;