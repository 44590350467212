import React from "react";
import './CardGrid.scss';
import { checkAccess } from "../utils/apiUtils";
import DashCard from "../components/DashCard.js";

function DashCardGrid() {
  var inc = 0;
  const roleId = JSON.parse(window.sessionStorage.getItem("role_id"))?.role_id;
  const dashboardInfo = JSON.parse(sessionStorage.getItem("dashboard_info"))?.dashboard_info ?? [];
  
  let gridItems = [];

  dashboardInfo.forEach((item) => {
      gridItems.push({
          label: item.menu_name,
          url: "/" + item.menu_accessor,
      });
  });

  console.log('Dashboard Info:', dashboardInfo);
  console.log('Grid Items:', gridItems);

  return (
    <div className="dash-grid-container">
      {gridItems.map((gridItem, index) => {
        const module = gridItem.url.replace('/', '');
        const acc = checkAccess(module);
        console.log('Checking access for module:', module, 'Access:', acc);

        if (!acc) {
          console.log('Access denied for module:', module);
          return null;
        }

        // if (roleId != 1 && module === "report-bilty") {
        //   console.log('Role ID does not permit report-bilty:', roleId);
        //   return null;
        // }

        inc += 1;

        return (
          <DashCard key={index} index={inc} item={gridItem} />
        );
      })}
    </div>
  );
}

export default DashCardGrid;
