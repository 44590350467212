import React, { useEffect, useState } from "react";
import Autosuggest from "react-autosuggest";
import { NarrationMasterApiConfig } from "../config/apiConfig.js";

import {
    NarrationMasterGroupInfo,
    NarrationMasterDataObject,
    NarrationMasterValidate,
} from "../config/NarrationMasterConfig"
import "./Form.css";
import "./ChallanForm.css";
import "./AutoSuggest.css";
import useForm from "./useForm";
import "./popup.css";
import { SERVER_URL } from "../config/config.js";

const NarrationMaster = ({sessionObject}) => {

    const fetchGroupList = async() => {
        const url = SERVER_URL + "/narration/group-info" + "?companyId=" + myForm.pageState.company_id;

        const resp = await fetch(url);

        if(resp.ok) {
            const data = await resp.json();
            console.log(data);

            let tempData = {};
            data.forEach(obj => {
                tempData[obj.id] = obj.name;
            })
            console.log(tempData);
            setGroupList(tempData);
            myForm.setPageState({
                ...myForm.pageState,
            })
        }
    }

    useEffect(() => {
        // fetchGroupList();
    }, [])

    let variablesFromSession = {
        station_from: String(sessionObject.sessionVariables.branch_id),
        station_from_name: sessionObject.sessionVariables.branch_name,
        created_from: String(sessionObject.sessionVariables.branch_id),
        created_by: String(sessionObject.sessionVariables.user_id),
        company_id: sessionObject.sessionVariables.company_id ?? "1",
    };

   
    const myForm = useForm(
        "NarrationMaster",
         NarrationMasterValidate,
        { ...NarrationMasterDataObject, ...variablesFromSession },
        NarrationMasterApiConfig
    );

    const getAdditionalInfoForSuggestionFetch = (fieldInfoObject) => {
        return null;    
    };

    console.log("PAGE",myForm.pageState);


    async function handleSave() {
        console.log("VALUE",myForm.pageState);
        let inputData = {
            name: myForm.pageState.name,
        }
        let url = SERVER_URL + "/narration/";
        const rawResponse = await fetch(url, {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify(inputData),
        })
        .catch((err) => {
            console.log({err});
            // throw new Error(err)
        });

        let responseObject = await rawResponse?.json();   
        return responseObject;
    }


    return(
        <div className="item-master-form-container">
           <div className="form-header">Narration Master</div>

           <div className="form" noValidate>
           <div className="form-title">
                <div className="form-row">
                    <label className="form-label">Id: </label>
                </div>
            </div>

            <div className="form-input-content-block-0">
                <div className="narration-box">
                {NarrationMasterGroupInfo["group-1"].map(function (info) {
                    
                            if (info["type"] === "dropdown")
                                return (
                                    <div className={info["className"]} key={info["name"]}>
                                        <label className={info["labelClassName"]}>
                                            {info["label"]}
                                        </label>
                                        <select
                                            className={info["inputClassName"]}
                                            onChange={(newValue) => {
                                                myForm.handleChangeForSelect(newValue, info["name"]);
                                            }}
                                        >
                                            {info["dropdown_items"].map((dropdown_item) => (
                                                <option
                                                    value={dropdown_item.value}
                                                    key={dropdown_item.label}
                                                >
                                                    {dropdown_item.label}
                                                </option>
                                            ))}
                                        </select>
                                        {myForm.internalValidationErrors[info["name"]] && (
                                            <p>{myForm.internalValidationErrors[info["name"]]}</p>
                                        )}
                                    </div>
                                );
                            else
                                return (
                                    <div className={info["className"]} key={info["name"]}>
                                        <label className={info["labelClassName"]}>
                                            {info["label"]}
                                        </label>
                                        <Autosuggest
                                        id={info["name"]}
                                        // suggestions={myForm.suggestions}
                                        suggestions = {(myForm.suggestions != undefined) ? myForm.suggestions : ""}  // changes made for removing error that was caused by undefined suggestion
                                        onSuggestionsFetchRequested={(a) =>
                                            myForm.onSuggestionsFetchRequested(a, (b) =>
                                                myForm.suggestionFetchApi(
                                                    info,
                                                    b,
                                                    getAdditionalInfoForSuggestionFetch(info)
                                                )
                                            )
                                        }
                                        onSuggestionsClearRequested={() =>
                                            myForm.onSuggestionsClearRequested(info)
                                        }
                                        getSuggestionValue={suggestion => suggestion[info.suggestionKeyword]}
                                        onSuggestionSelected={(a, b) => {
                                            console.log("selected");
                                            return myForm.getSuggestionValue(
                                                b.suggestion,
                                                info,
                                                myForm.performSuggestions,
                                                myForm.updatePageStateForGetSuggestion
                                            )
                                        }
                                        }
                                        renderSuggestion={(a) => myForm.renderSuggestion(a, info)}
                                        highlightFirstSuggestion={true}
                                        ref={(a) => myForm.storeInputReference(a, false)}
                                        inputProps={{
                                            placeholder: info["placeholder"],
                                            value: String(myForm.pageState[info["name"]]),
                                            onChange: (a, b) => {
                                                myForm.onChangeAutoSuggest(a, b, info);
                                            },
                                            onBlur: () => {
                                                info["toValidate"]
                                                    ? myForm.onblurValidator(info)
                                                    : {};
                                            },
                                            onKeyPress: (a) => myForm.onKeyPressForKeyNav(a, info),
                                            disabled: myForm.pageMode == "view" ? "disabled" : "",
                                        }}
                                    />
                                        {myForm.internalValidationErrors[info["name"]] && (
                                            <p>{myForm.internalValidationErrors[info["name"]]}</p>
                                        )}
                                    </div>
                                );
                        })}
                </div>
            </div>

            <div className="footer">
                
                    <button
                        onClick={handleSave}
                        type="button"
                        className="btn btn-primary"
                        ref={(a) => myForm.storeInputReferenceForSelect(a, "save_button")}
                    >
                        Save
                    </button>  

                    <button
                        onClick={myForm.handleSubmit}
                        type="button"
                        className="btn btn-primary"
                        ref={(a) => myForm.storeInputReferenceForSelect(a, "update_button")}
                        >
                        Update
                    </button>
                
                
            
            </div>

           </div>
        </div>
    )
}

export default NarrationMaster;