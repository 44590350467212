import React, { useEffect, useState } from "react";
import "./summaryStationWise.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import LoadingOverlay from "react-loading-overlay";
import { SERVER_URL, USE_OVERLAY } from "../config/config";

const SummaryStationWise = ({ sessionObject }) => {
    const [overlay, setOverlay] = useState(false);
    const [data, setData] = React.useState([])

    const [dateState, setDateState] = useState({
        date_from: new Date(),
        date_to: new Date(),
    });

    const fetchData = () => {
        setOverlay(true)
        fetch(SERVER_URL + '/report', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ date_dict: dateState })
        })
            .then(response => response.json())
            .then(data => { setData(data.data); setOverlay(false) })
            .catch(error => console.error('Error:', error));
    }

    useEffect(() => {
        fetchData()
    }, [dateState])

    const mergeDuplicates = (data) => {
        const mergedData = {};

        data.forEach((item) => {
            const key = `${item.station_from_name}_${item.station_to_name}`;

            if (!mergedData[key]) {
                mergedData[key] = { ...item, bilty_no_count: 1 };
            } else {
                mergedData[key].pkgs = Number(mergedData[key].pkgs) + Number(item.pkgs);
                mergedData[key].bilty_charge = Number(mergedData[key].bilty_charge) + Number(item.bilty_charge);
                mergedData[key].hamali = Number(mergedData[key].hamali) + Number(item.hamali);
                mergedData[key].freight = Number(mergedData[key].freight) + Number(item.freight);
                mergedData[key].dd_charge = Number(mergedData[key].dd_charge) + Number(item.dd_charge);
                mergedData[key].bilty_no_count++;
                mergedData[key].total_amount = Number(mergedData[key].total_amount) + Number(item.total_amount);
            }
        });

        return Object.values(mergedData);
    };

    const getUniqueStationNames = () => {
        const stationNames = new Set();
        data.forEach((item) => {
            stationNames.add(item.station_from_name.toUpperCase());
        });
        return Array.from(stationNames);
    };

    const calculateTotal = (data) => {
        const total = {
            pkgs: 0,
            bilty_charge: 0,
            hamali: 0,
            freight: 0,
            dd_charge: 0,
            bilty_no_count: 0,
            total_amount: 0,
        };

        data.forEach((item) => {
            total.pkgs += Number(item.pkgs || 0);
            total.bilty_charge += Number(item.bilty_charge || 0);
            total.hamali += Number(item.hamali || 0);
            total.freight += Number(item.freight || 0);
            total.dd_charge += Number(item.dd_charge || 0);
            total.bilty_no_count += Number(item.bilty_no_count || 0);
            total.total_amount += Number(item.total_amount || 0);
        });

        return total;
    };

    const mergedData = mergeDuplicates(data);

    return (
        <div className="summaryContainer">
            {USE_OVERLAY && (
                <LoadingOverlay
                    active={overlay}
                    spinner
                    text="Loading your content..."
                    styles={{
                        wrapper: {
                            overflow: true ? "hidden" : "scroll",
                        },
                    }}
                ></LoadingOverlay>
            )}
            <div className="dateHolder">
                <div>
                    From Date:{" "}
                    <DatePicker
                        dateFormat="dd-MM-yyy"
                        selected={dateState.date_from}
                        onChange={(date) =>
                            setDateState({
                                ...dateState,
                                ["date_from"]: date,
                            })
                        }
                    />
                </div>
                <div>
                    To Date:{" "}
                    <DatePicker
                        dateFormat="dd-MM-yyy"
                        selected={dateState.date_to}
                        onChange={(date) =>
                            setDateState({
                                ...dateState,
                                ["date_to"]: date,
                            })
                        }
                    />
                </div>
            </div>
            <div className="titleHolder">
                <span>Destination Wise Booking Summary Report</span>
            </div>
            <div className="summaryTable">
                {getUniqueStationNames().map((stationName) => (
                    <div key={stationName}>
                        <div className="branchTitleHolder">
                            <span className="branchTitle">Branch: {stationName}</span>
                        </div>
                        <table id="tabled">
                            <thead>
                                <tr>
                                    <th>Sr No.</th>
                                    <th>Station To</th>
                                    <th>Packages</th>
                                    <th>No of Bilty</th>
                                    <th>Freight</th>
                                    <th>Bilty Charge</th>
                                    <th>Hamali</th>
                                    <th>DD Charge</th>
                                    <th>Total Amount</th>
                                </tr>
                            </thead>
                            <tbody>
                                {mergedData
                                    .filter((item) => item.station_from_name === stationName)
                                    .map((item, index) => (
                                        <tr key={index}>
                                            <td>{index + 1}</td>
                                            <td>{item.station_to_name.toUpperCase()}</td>
                                            <td>{item.pkgs || 0}</td>
                                            <td>{item.bilty_no_count || 0}</td>
                                            <td>{item.freight || 0}</td>
                                            <td>{item.bilty_charge || 0}</td>
                                            <td>{item.hamali || 0}</td>
                                            <td>{item.dd_charge || 0}</td>
                                            <td>{item.total_amount || 0}</td>
                                        </tr>
                                    ))}
                                <tr className="totalLabel">
                                    <td></td>
                                    <td>Total:</td>
                                    <td>{calculateTotal(mergedData.filter((item) => item.station_from_name === stationName)).pkgs}</td>
                                    <td>{calculateTotal(mergedData.filter((item) => item.station_from_name === stationName)).bilty_no_count}</td>
                                    <td>{calculateTotal(mergedData.filter((item) => item.station_from_name === stationName)).freight}</td>
                                    <td>{calculateTotal(mergedData.filter((item) => item.station_from_name === stationName)).bilty_charge}</td>
                                    <td>{calculateTotal(mergedData.filter((item) => item.station_from_name === stationName)).hamali}</td>
                                    <td>{calculateTotal(mergedData.filter((item) => item.station_from_name === stationName)).dd_charge}</td>
                                    <td>{calculateTotal(mergedData.filter((item) => item.station_from_name === stationName)).total_amount}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default SummaryStationWise;
